import $ from "jquery";
import utils from "utils";
import env from "bnc-env";
import comAuth from "bnc-auth";
import mmcheck from "bnc-mmcheck";
import Vue from "vue";
import bncToast from "bnc-toast";
import bncSecurity from "bnc-security";
import bncDialog from "bnc-dialog";
import bncVcode from "bnc-verification-code";
import bncBizUiFooterLogin from "bnc-ui-footer-login";

let vm = new Vue({
    el: "#vueBox",
    data: {
        loginType: 1,
        // currentProjectEnv: "",
        form: {
            phone: "",
            password: "",
            phonecode: "",
            code: "",
        },
        pcodeUrl: null,
        // company: {
        //     name: "Copyright © 2021 巨网云互联（北京）科技股份有限公司. All Rights Reserved.",
        //     icp: "京ICP备18063915号-1",
        // },
        isShowPwd: false, //给密码加明文暗纹
    },
    created() {
        bncBizUiFooterLogin.initPageDom();

        // this.currentProjectEnv = urls.currentProjectEnv;
        // switch (this.currentProjectEnv) {
        //     case "anxun":
        // $("body").attr("id", "anxun");
        // this.company = {
        //     name: "Copyright © 2019-2025 浙江安迅应急救援科技有限公司",
        //     icp: "浙ICP备19015716号-1",
        // };
        // break;
        // case "dfyx":
        // $("body").attr("id", "dfyx");
        // this.company = {
        //     name: "Copyright © 河南省钱币有限公司 版权所有",
        //     icp: "豫ICP备20001968号",
        // };
        // break;
        // case "light":
        // this.company = {
        //     name: "Copyright © 吉灵网络科技（浙江）有限责任公司",
        //     icp: "浙ICP备2022011631号-1",
        // };
        // break;
        // case "huanke":
        // this.company = {
        //     name: "Copyright@2024  浙江博信数智科技有限公司",
        //     icp: " 浙ICP备2024060958号-3",
        // };
        // break;
        // default:
        // $("body").attr("id", "bnc");
        // break;
        // }
    },
    computed: {
        isShowLoginBtn() {
            let form = this.form;
            if (form.phone !== "" && form.password !== "") {
                return true;
            }
            if (form.phonecode !== "" && form.code !== "") {
                return true;
            }
            return false;
        },
    },
    methods: {},
    mounted() {},
});
/**
 * 页面参数
 * loginType:1-用户名密码登录;2-验证码登录
 * canSubmit:0-可以提交;1-不能提交
 * submitCount:提交次数(触发人机验证)
 */
var loginManager = {
    loginType: 1,
    canSubmit: true,
    submitCount: 0,
    /**
     * 抽取数据
     */
    findData: function () {
        var param = {
            authtype: this.loginType,
        };
        if (param.authtype === 1) {
            param.principal = $("#passwordForm [name='username']").val();
            param.credentials = $("#passwordForm [name='password']").val();
        } else {
            param.principal = $("#vcodeForm [name='phonenumber']").val();
            param.credentials = $("#vcodeForm [name='vcode']").val();
        }
        return param;
    },
    validatePhone: function (phone) {
        return phone && /^1\d{10}$/.test(phone);
    },
    /**
     * 验证:TODO 后面再做标准组件修改
     */
    validate: function (data) {
        var r = false;
        if (!data) {
            bncToast.errorAlert("输入的信息不能为空");
        } else if (utils.checkIsNullOrEmpty(data.principal)) {
            bncToast.errorAlert("手机号不能为空");
        } else if (!this.validatePhone(data.principal)) {
            bncToast.errorAlert("手机号格式错误");
        } else if (utils.checkIsNullOrEmpty(data.credentials)) {
            bncToast.errorAlert(data.authtype === 1 ? "密码不能为空" : "验证码不能为空");
        } else {
            r = true;
        }
        return r;
    },
    /**
     * 提交
     */
    login: function () {
        if (!this.canSubmit) {
            console.log("错误次数超过" + this.submitCount + "次，需要完成人机验证");
            bncToast.errorAlert("请先完成验证");
            return;
        }
        var param = this.findData();
        if (this.validate(param)) {
            comAuth.auth(param, function (res) {
                if (res.code === 0) {
                    env.doAjax("/bcenter/operator/completion_mark_info", null, null).then(ret => {
                        if (ret.data) {
                            let redirect = utils.getQueryString("redirect", true);
                            if (redirect && decodeURIComponent(redirect).includes("/waste-model-datascreen")) {
                                $.ajax({
                                    url: env.restUrl(`/v2/digital/mall/wasteoil/big_screen/canViewBigScreenData`),
                                    type: "get",
                                    dataType: "json",
                                    async: false,
                                    cache: false, //不使用缓存
                                    success: function (res) {
                                        // 登录人角色不对
                                        if (res.code == 500) {
                                            bncDialog.closeLoading();
                                            bncToast.errorAlert(res.msg);
                                            return;
                                        }
                                        // 登录成功跳转
                                        if (res.code == 0) {
                                            if (res.data) {
                                                window.location.href = decodeURIComponent(redirect);
                                                bncDialog.closeLoading();
                                            } else {
                                                bncDialog.closeLoading();
                                                bncToast.errorAlert(
                                                    "您未授权查看智油仓废油数据平台，请联系平台运营商开通权限。",
                                                );
                                                return;
                                            }
                                        }
                                    },
                                    error: function (err) {
                                        console.log(err);
                                    },
                                });
                                return;
                            } else if (decodeURIComponent(redirect).includes("/market-model-datascreen")) {
                                location.href = decodeURIComponent(redirect);
                                bncDialog.closeLoading();
                            } else {
                                window.location.href = "../home-page/index.html";
                            }
                        } else {
                            window.location.href = "../active/index.html";
                        }
                    });
                } else {
                    loginManager.failSubmit();
                    bncToast.success(res.msg);
                }
            });
        }
    },
    /**
     * 检查能否提交
     * up: true 代表canSubmit自增一个  否则直接判断当前结果
     */
    failSubmit: function () {
        this.submitCount++;
        this.canSubmit = this.submitCount < 2;
        if (!this.canSubmit) {
            $("#mmcheck").removeClass("d-none");
            if (this.submitCount > 2) {
                mmcheck.reset("#mmcheck");
            }
            mmcheck.show("#mmcheck");
            $("#loginbtn").removeClass("btn-primary").addClass("btn-secondary");
        }
        return this.canSubmit;
    },
};
$(function () {
    initData();
    initUi();
    initEvent();
});

function initData() {}

function initUi() {
    // 设置logo
    $("#logo").attr("src", env.restUrl("/logo/logo/default"));
    var crtime = "2014-" + new Date().getFullYear();
    $("#crtime").text(crtime);
}

function initEvent() {
    // 绑定回车提交
    $("form input").bind("keydown", function (e) {
        if (e.keyCode == "13") {
            loginManager.login();
        }
    });
    // 提交
    $("#loginbtn").bind("click", function () {
        loginManager.login();
    });
    // 人机校验
    mmcheck.bind("#mmcheck", function () {
        loginManager.canSubmit = true;
        $("#loginbtn").removeClass("btn-secondary").addClass("btn-primary");
    });
    // 改变登录方式
    $("#changeLogin")
        .unbind()
        .bind("click", function () {
            vm.form = {
                phone: "",
                password: "",
                phonecode: "",
                code: "",
                pcode: "",
            };
            $("#mmcheck").html("");
            if (loginManager.loginType == 1) {
                $("form").removeClass("d-none").filter("#passwordForm").addClass("d-none");
                $("#changeLogin small").text("使用密码登录");
                $("#changeLogin img").attr("src", "../../../assets/img/login_password.png");
                vm.loginType = 2;
                loginManager.loginType = 2;
                $(".switchbox a").hide();
            } else {
                $("form").removeClass("d-none").filter("#vcodeForm").addClass("d-none");
                $("#changeLogin small").text("使用验证码登录");
                $("#changeLogin img").attr("src", "../../../assets/img/login-cut.png");
                loginManager.loginType = 1;
                vm.loginType = 1;
                $(".switchbox a").show();
            }
        });
    // 重置密码
    $("#resetPassword")
        .unbind()
        .bind("click", function () {
            window.location.href = "../retrieve/index.html";
        });
    // 发送验证码
    $("#sendvcode")
        .unbind()
        .bind("click", function () {
            var data = loginManager.findData();
            data.credentials = "****";
            if (loginManager.validate(data)) {
                bncVcode.open(
                    {
                        type: 1,
                        phone: data.principal,
                        pcode: "",
                    },
                    "#sendvcode",
                    function (ret) {
                        if (ret.code === 0) {
                            bncToast.success("验证码已发送");
                        } else {
                            bncToast.errorAlert(ret.msg);
                        }
                    },
                );
            }
        });
}
